<template>
  <v-main>
    <v-card
      class="ma-2"
      flat
    >
      <v-card-title>Товары</v-card-title>
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="4"
            sm="4"
            class="text-right"
          >
            <v-switch
              v-model="isShowOnlyNew"
              label="Только новинки"
              class="mt-5"
            />
            <v-radio-group v-model="orderFromChina" v-show="showFromChina" @change="clearSelectedItems()" class="mt-5">
              <v-radio label="Заказ со склада" selected :value="false"></v-radio>
              <v-radio label="Заказ с производства" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="4"
            class="text-right"
          >
            <!-- В случае, если авторизован Менеджер маркетплейсов, то отображается переключатель radio-group,
            позволяющий выбрать ход заявки -->
            <v-radio-group
              v-if="isMarketplaceManager && !getOrderId"
              v-model="orderType"
              mandatory
            >
              <v-radio
                label="FBO"
                value="FBO"
              />
              <v-radio
                label="FBS"
                value="FBS"
              />
            </v-radio-group>
            <!-- Чтобы выйти из редактирования заявки -->
            <v-btn
              v-if="isMarketplaceManager && getOrderId"
              color="yellow"
              :loading="getLoadingState"
              @click="clearOrder"
            >
              Отменить редактирование
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="4"
            sm="4"
            class="text-right"
          >
            <v-btn
              color="primary"
              :disabled="selectedItems.length === 0"
              :loading="getLoadingState"
              @click="createOrder"
            >
              Добавить в заказ
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <!-- Для Менеджера маркетплейсов обязательно должен быть выбран какой-то склад, поэтому осуществляется
            валидация и исключается наименование "Все склады"-->
            <v-select
              v-if="!isMarketplaceManager || !isPurchasingManager || !getOrderId"
              v-model="pStock"
              :items="getStockList(isMarketplaceManager || isPurchasingManager)"
              hide-details
              :label="isMarketplaceManager ? 'Склад назначения' : 'Склад'"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-select
              v-model="pCategory"
              :items="getCategories()"
              hide-details
              item-text="title"
              item-value="id"
              label="Категория"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <v-text-field
              v-model="pSearch"
              append-icon="mdi-magnify"
              hide-details
              label="Найти"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="3"
          >
            <!-- Введено свойство v-if для DatePickerRange, для того чтобы не отображать DatePickerRange, если авторизован
          Менеджер маркетплейсов -->
            <DatePickerRange
              v-if="hideDatePeriod"
              v-model="pSoldDateRange"
              label="Продано за период"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <HandyScroll ref="stocksTableHandScrolls">
              <v-data-table
                v-model="selectedItems"
                :footer-props="{ 'items-per-page-options': [50, 100, -1] }"
                :headers="tableHeaders"
                :items-per-page="50"
                :items="stocks"
                :loading="getLoadingState"
                :search="pSearch"
                :sort-desc="true"
                class="v-stockstable"
                dense
                group-by="category.sorted_group"
                item-key="id"
                show-select
                sort-by="quantity_sold"
              >
                <template #[`group.header`]="{ group, headers }">
                  <td :colspan="headers.length">
<!--                    {{group}}-->
                    {{ trimGroup(group) }}
                  </td>
                </template>
                <template #[`item.is_new`]="{ item }">
                  <v-icon
                    v-if="item.is_new"
                    color="green"
                    large
                    title="Новый товар"
                  >
                    mdi-new-box
                  </v-icon>
                </template>
              </v-data-table>
            </HandyScroll>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-main>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import HandyScroll, {EventBus} from "vue-handy-scroll";
import DatePickerRange from "@/components/DatePickerRange";

export default {
  name: "Stocks",
  components: {
    DatePickerRange,
    HandyScroll,
  },
  data: () => ({
    selectedItems: [],
    isShowOnlyNew: false,
    orderFromChina: false,
    pSoldDateRange: [
      new Date(new Date().setDate(new Date().getDate() - 31))
        .toISOString()
        .substr(0, 10),
      new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .substr(0, 10),
    ],
    pSearch: "",
    pCategory: -1,
    pStock: "",
    orderType: ""
  }),
  computed: {
    ...mapGetters("auth", ["isWholeSaleGroup", 'isMarketplaceManager', "isPurchasingManager"]),
    ...mapGetters("catalog", ["getCategories", "getCategory"]),
    ...mapGetters("clients", ["getStockList", "getUserCompany"]),
    ...mapGetters("stocks", [
      "getStocks",
      "getLoadingState",
      "getOrderId",
      "getItems",
      "getOrderFromChina",
    ]),
    showFromChina() {
      return !(this.isMarketplaceManager || this.isPurchasingManager);

    },
    hideDatePeriod() {
      return !(this.isMarketplaceManager || this.isWholeSaleGroup);

    },
    stocks() {
      let stock = this.getStocks;
      stock = this.isShowOnlyNew ? stock.filter((el) => el.is_new) : stock;
      stock = !this.orderFromChina
        ? stock.filter((el) => el.quantity_on_base_stocks > 0)
        : stock;
      stock = this.orderFromChina
        ? stock.filter((el) => el.is_visible_order_from_china === true)
        : stock;
      stock = stock.map((el) => ({
        ...el,
        category: this.getCategories(true).find(
          (cat) => cat.id === el.category
        ),
      }));
      stock =
        this.pCategory !== -1
          ? stock.filter(
            (el) => !!el.category && el.category.root_id === this.pCategory
          )
          : stock;
      return stock;
    },
    tableHeaders() {
      let headers = [
        // Общие столбцы
        {text: "", value: "is_new", sortable: false},
        {text: "Наименование", value: "title", sortable: false},
        // Столбцы Франчайзи
        {text: "Артикул", value: "vendor_code", sortable: false},
        {
          text: this.isPurchasingManager ? 'Остаток склада «Резерв»' : "Остаток на складе поставщика",
          value: "quantity_on_base_stocks",
          sortable: false,
          orderFromChina: false,
          isMarketplaceManager: false
        },
        {
          text: "Текущий остаток в магазине",
          value: "quantity_on_stocks",
          isMarketplaceManager: false,
          sortable: false,
          isWholeSaleGroup: false,
        },
        {
          text: "Продано",
          value: "quantity_sold",
          isMarketplaceManager: false,
          sortable: false,
          isWholeSaleGroup: false,
        },
        {text: "В обработке", value: "quantity_in_progress", sortable: false, orderFromChina:false, isMarketplaceManager: false,  },
        {text: "В пути", value: "quantity_in_delivery", sortable: false, orderFromChina:false, isMarketplaceManager: false,  },
        {text: "В обработке с производства", value: "quantity_in_progress_from_china", sortable: false, orderFromChina:true, isMarketplaceManager: false},
        {text: "В пути с производства", value: "quantity_in_delivery_from_china", sortable: false, orderFromChina:true, isMarketplaceManager: false},
        {text: "Штрих-код", value: "bar_code", sortable: false, isMarketplaceManager: true},
        {
          text: "Склад остаток",
          value: "quantity_on_base_stocks",
          sortable: false,
          orderFromChina: false,
          isMarketplaceManager: true
        },
        {
          text: "Зарезервировано на складе по FBO",
          value: "quantity_in_progress_fbo",
          sortable: false,
          isMarketplaceManager: true
        },
        {
          text: "Зарезервировано на складе по FBS",
          value: "quantity_in_progress_fbs",
          sortable: false,
          isMarketplaceManager: true
        },
        {text: "В пути со склада", value: "quantity_in_delivery", sortable: false, isFBO: true},
      ];
      // Фильтрация отображаемых столбцов таблицы в зависимости от роли авторизованного пользователя, типа заявки
      // (для Менеджера маркетплейсов) и переключателя "С производства" (для Франчайзи)
      headers = this.isMarketplaceManager
        ? headers.filter((el) => el.isMarketplaceManager !== false)
        : headers.filter((el) => el.isMarketplaceManager !== true);
      headers = (this.isWholeSaleGroup)
        ? headers.filter((el) => el.isWholeSaleGroup !== false)
        : headers.filter((el) => el.isWholeSaleGroup !== true);
      headers = this.orderType === "FBO"
        ? headers
        : headers.filter((el) => el.isFBO !== true);
      return this.orderFromChina
        ? headers.filter((el) => el.orderFromChina !== false)
        : headers.filter((el) => el.orderFromChina !== true);
    },
    changeOrderChinaMessage() {
      if (this.getOrderId) {
        return `В настоящее время вы редактируете заказ (№ ${this.getOrderId}). Нажатие на кнопку "ОК" отменит это редактирование.`;
      } else {
        return `В настоящее время вы создаёте заказ. Нажатие на кнопку "ОК" отменит его создание.`;
      }
    },
  },
  watch: {
    pStock() {
      if (!this.isMarketplaceManager) {
        if (!this.getLoadingState) this.getStocksItems();
      }
    },
    getUserCompany() {
      this.pStock = "";
      this.getStocksItems();
    },
    pSoldDateRange(date) {
      if (date.length === 2) {
        if (Date.parse(date[0]) > Date.parse(date[1])) date.reverse();
        this.getStocksItems();
      }
    },
    stocks() {
      EventBus.$emit("update", {sourceElement: this.$refs.stocksTableHandScrolls.$el});
    },
  },
  created() {
    this.updateCategories();
    this.updateStockList();
    this.getStocksItems();
    this.orderFromChina =
      this.getOrderFromChina !== null ? this.getOrderFromChina : false;
  },
  methods: {
    ...mapActions("catalog", ["updateCategories"]),
    ...mapActions("clients", ["updateStockList"]),
    ...mapActions("stocks", [
      "updateStockItems",
      "pushItems",
      "clearItems",
      "clearOrder",
      "setOrderFromChina",
      "setStockTo",
      "setOrderType",
      "setSoldDateRange"
    ]),
    clearSelectedItems() {
      this.selectedItems = [];
      this.clearItems();
    },
    trimGroup(group) {
      const trimmed = group.replace(/^\d+\.\s*/, '');
      return trimmed;
    },
    getStocksItems() {
      this.updateStockItems({
        stock: this.pStock,
        sold_date__gte: this.pSoldDateRange[0],
        sold_date__lte: this.pSoldDateRange[1],
      });
    },
    orderFromChinaChange() {
      if (
        (!!this.getOrderId || !!this.getItems.length) &&
        this.orderFromChina !== !!this.getOrderFromChina
      ) {
        if (confirm(this.changeOrderChinaMessage)) {
          this.orderFromChina = !this.orderFromChina;
          this.clearOrder();
        }
      } else {
        this.orderFromChina = !this.orderFromChina;
      }
    },
    // Менеджер маркетплейсов обязательно должен выбрать какой-то склад (в случае если не осуществляется редактирование
    // существующей заявки (FBS))
    validateStock() {
      let valid = true;
      if (!this.getOrderId && this.pStock === "" && (this.isMarketplaceManager || this.isPurchasingManager)) {
        this.$store.dispatch("alertError", "Выберите, пожалуйста, склад");
        valid = false;
      }
      return valid;
    },
    calculateItemQuantity(el){
      if (this.orderFromChina){
        return el.min_order_factory_quantity;
      }
      else if (this.isMarketplaceManager){
        return 1
      }
      else {
        return 10;
      }
    },
    createOrder() {
      if (this.validateStock()) {
        this.setStockTo(this.pStock);
        this.setSoldDateRange(this.pSoldDateRange);
        this.pushItems({
          payload: this.selectedItems.map((el) => ({
            product: el.id,
            order: this.calculateItemQuantity(el),
          })),
        });
        if (this.getOrderId) {
          this.$router.push({
            name: "OrderEdit",
            params: {id: this.getOrderId},
          });
        } else {
          if (this.isMarketplaceManager) {
            this.setStockTo(this.pStock);
            this.setOrderType(this.orderType);
          }
          if (this.isPurchasingManager){
            this.setStockTo(this.pStock);
          }
          this.setOrderFromChina(this.orderFromChina);
          this.$router.push({name: "OrderCreate"});
        }
      }
    },
  },
};
</script>

<style scope>
.v-stockstable .v-data-table__wrapper {
  overflow: unset;
}

.handy-scroll {
  margin-bottom: 36px !important;
}
</style>
