<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        v-bind="attrs"
        :label="label"
        hide-details
        prepend-icon="mdi-calendar"
        readonly
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      :max="new Date().toISOString().substr(0, 10)"
      no-title
      range
      scrollable
      :show-current="false"
      :first-day-of-week="1"
      @input="dialog = value.length === 1 ? false : true"
    />
  </v-menu>
</template>

<script>
export default {
  props: {
    label: String,
    value: Array,
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    dateRangeText() {
      return this.value.join(" - ");
    },
    date: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
